import React from "react";

const Menu = ({ items }) => {
  return (
    <div className="section-center-beauty">
      {items.map((item) => {
        const { id, title, img, desc, price } = item;
        return (
          <article key={id} className="menu-item-beauty">
            {/* <img src={img} alt={title} className="photo" /> */}
            <div className="item-infobeauty">
              <header>
                <div className="left-text-beauty">
                  <h4>{title}</h4>
                  <p className="item-text">{desc}</p>
                </div>
                <h4 className="price_beauty">{price}</h4>
              </header>
              
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Menu;

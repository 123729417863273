import React from "react";

const Feed = (props) => {
  const { id, caption, media_type, media_url } = props.feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <div class="videoWrapper">

        <video
          width="100%"
          height="350px"
          src={media_url}
          type="video/mp4"
          controls
          playsinline
          autoplay
        ></video>
</div>
        // <div class="videoWrapper">
        //   <iframe
        //     width="100%"
        //     height="auto"
        //     src={media_url}
        //     frameborder="0"
        //     allowfullscreen
        //     muted 
        //     autoplay
        //   ></iframe>
        // </div>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        // <img width="100%" height="auto" id={id} src={media_url} alt={caption} style={{objectFit:"cover"}}/>
        <img
          id={id}
          src={media_url}
          alt={caption}
          style={{ objectFit: "cover", height: "350px", width: "350px" }}
        />
      );
      break;
    default:
      post = (
        <img
          // width="100%"
          // height="auto"
          id={id}
          src={media_url}
          alt={caption}
          style={{ objectFit: "cover", height: "350px", width: "350px" }}
        />
      );
  }

  return <React.Fragment>{post}</React.Fragment>;
};

export default Feed;

import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // font-family: 'Source Sans Pro',sans-serif;
    // font-family: 'Cormorant Garamond', serif;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin: ${({ margin }) => (margin ? margin : "0 auto")};
  padding: ${({ padding }) => (padding ? padding : "0 50px")};

  @media screen and (max-width: 960px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const MainHeading = styled.h1`
  font-size: clamp(2.3rem, 6vw, 4.5rem);
  margin-bottom: 2rem;
  color: ${({ inverse }) => (inverse ? "$403ae3" : "#fff")};
  width: 100%;
  text-align: center;
  letter-spacing: 4px;
`;

export const MainHeading_nail = styled.h1`
  font-size: clamp(2.3rem, 6vw, 4.5rem);
  margin-bottom: 0rem;
  background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 100%;
  text-align: center;
  letter-spacing: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Heading = styled.h2`
  font-size: clamp(1.3rem, 6vw, 3.1rem);
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  color: ${({ inverse }) => (inverse ? "$403ae3" : "#fff")};
  letter-spacing: 0.4rem;
  text-align: center;
  width: ${({ width }) => (width ? width : "")};
`;

export const TextWrapper = styled.span`
  font-size: ${({ size }) => (size ? size : "")};
  font-weight: ${({ weight }) => (weight ? weight : "")};
  letter-spacing: ${({ spacing }) => (spacing ? spacing : "")};
  text-align: ${({ align }) => (align ? align : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  color: ${({ color }) => (color ? color : "")};
  @media screen and (max-width: 768px) {
    font-size: ${({ smSize }) => (smSize ? smSize : "")};
  }
`;

export const TextWrapperHero = styled.span`
  width: 25rem;
  width: max-content;
  font-size: ${({ size }) => (size ? size : "")};
  font-weight: ${({ weight }) => (weight ? weight : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
    // border: 1px solid yellow;
  text-align: center;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    margin: 0 0;
  }
`;

export const Section = styled.section`
  padding: ${({ padding }) => (padding ? padding : "140px 0")};
  margin: ${({ margin }) => (margin ? margin : "")};
  background: ${({ inverse }) => (inverse ? "#000" : "white")};
  color: ${({ inverse }) => (inverse ? "white" : "black")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};

  @media screen and (max-width: 768px) {
    padding: ${({ smPadding }) => (smPadding ? smPadding : "70px 0")};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "")};
  align-items: ${({ align }) => (align ? align : "")};
  gap: ${({ gap }) => (gap ? gap : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "100%")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "")};
  color: ${({ color }) => (color ? color : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : "")};
  align-items: ${({ align }) => (align ? align : "")};
  gap: ${({ gap }) => (gap ? gap : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-bottom: ${({ mb }) => (mb ? mb : "")};
  margin-top: ${({ mt }) => (mt ? mt : "")};
  position: ${({ position }) => (position ? position : "")};
  width: ${({ width }) => (width ? width : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  color: ${({ color }) => (color ? color : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

export const Button = styled(motion.button)`
height: 3rem;
padding: 16px 32px;
font-weight: 700;
font-size: 0.8rem;
line-height: 18px;
letter-spacing: 1.54px;
text-transform: uppercase;
cursor: pointer;
background: none;
// color: ${({ inverse }) => (inverse ? 'black' : '#d4af37')};
color: #d4af37;

border-radius: 4px;
white-space: nowrap;
padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
outline: none;
border: 1px solid #d4af37;
cursor: pointer;
position: relative;
overflow: hidden;

&:before {
  background: linear-gradient(-45deg, #fed693, #ca7100);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  width: 0%;
  height: 100%;
}



&:hover:before {
  width: 200%;
}

&:hover {
  color: ${({ inverse }) => (inverse ? 'white' : 'black')};
}
`;

export default GlobalStyle;
